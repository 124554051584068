import { fetchToken, onMessageListener } from './firebase';

export const sendServerFcmToken = (token, cardId) => {
    console.log('Inside sendServerFcmToken with token:', token, 'and cardId:', cardId);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: token, cardId: cardId })
    };

    fetch(`api/registration/${cardId}`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error("Failed to send token to server");
            }
            console.log("Token sent successfully");
            console.log(response);
        })
        .catch(error => {
            console.error("Error sending token to server:", error);
        });
}

export const init = (setTokenFound) => {

    if ('Notification' in window) {
        if (Notification.permission === "granted") {
            return new Promise((resolve, reject) => {
                console.log('Access already granted! :)');

                fetchToken(setTokenFound)
                    .then((token) => {
                        if (token !== null && token !== undefined && token !== "" && token !== "undefined") {
                            const cardId = localStorage.getItem("cardId");
                            sendServerFcmToken(token, cardId);
                            resolve("Access granted and token fetched");
                        }
                    })
                    .catch(error => {
                        console.error('Synchronous error caught:', error);
                        reject({ status: "Error", message: "Error on fetchToken: " + error });
                    });
            });
        } else {
            return new Promise((resolve, reject) => {
                Notification.requestPermission(result => {
                    if (result === 'granted') {
                        console.log('Access granted! :)');

                        fetchToken(setTokenFound)
                            .then((token) => {
                                if (token !== null && token !== undefined && token !== "" && token !== "undefined") {
                                    const cardId = localStorage.getItem("cardId");
                                    sendServerFcmToken(token, cardId);
                                    resolve("Access granted and token fetched");
                                }
                            })
                            .catch(error => {
                                reject({ status: "Error", message: "Error on fetchToken: " + error });
                            });
                    } else if (result === 'denied') {
                        console.log('Access denied :(');
                        reject({ status: "denied", message: "Access denied" });
                    } else if (result === 'default') {
                        console.log("default");
                        reject({ status: "default", message: "No choice made" });
                    }
                });
            });
        }
    }
}