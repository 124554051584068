import { useParams, useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useState } from 'react';
import { createCardInfo, fetchWalletInfo } from '../cardService';
export function CreateCard() {

    const { cardIdEan } = useParams();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    let [error, setError] = useState(false);
    const [cardInfo, setCardInfo] = useState({ programName: '', logo: '', logoModal: '', image: '', backgroundColor: '' });

    let token = searchParams.get("token");

    useEffect(() => {
        fetchWalletInfo()
            .then(info => {
                setCardInfo(info);
            });
    }, []);


    useEffect(() => {
        createCardInfo(cardIdEan, token)
            .then(response => {
                console.log("creation card ok. redirecting")
                navigate(`/cards/${response.cardId}`);
            })
            .catch((reason) => {
                console.log(reason);
                console.log("Error in creation card. Contacts Supports");
                setError(true);
            })
            
    }, [cardIdEan, token])


    return (
            <header className="main-content card-creation">
            <div className="logo">
                <img src={cardInfo.logo} alt={cardInfo.programName} />
            </div>
            {error && <div className="card-creation-text">Nous avons rencontré une erreur inattendue lors de la configuration de la carte {cardIdEan}.<br/> Veuillez contacter le support.</div>}
            {!error && <div className="card-creation-text">
                    Configuration de la carte {cardIdEan} en cours !
                    <div className="custom-loader"></div>
                </div>
            }
            </header>
    );
}