import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
      return (
        <div style={{ textTransform: 'uppercase' }}>
            Veuillez cliquer sur le lien fourni dans votre email.
        </div>
    );
  }
}
