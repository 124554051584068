export const config = {
    ENVIRONMENT: process.env.REACT_APP_ENV,
    FIREBASE_APIKEY: process.env.REACT_APP_FIREBASE_APIKEY,
    FIREBASE_AUTHDOMAIN: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    FIREBASE_PROJECTID: process.env.REACT_APP_FIREBASE_PROJECTID,
    FIREBASE_STORAGEBUCKET: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    FIREBASE_MESSAGINGSENDERID: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    FIREBASE_APPID: process.env.REACT_APP_FIREBASE_APPID,
    FIREBASE_MEASUREMENTID: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
    FIREBASE_VAPIDKEY: process.env.REACT_APP_FIREBASE_VAPIDKEY
};