import { useParams, useNavigate } from "react-router-dom"
import { fetchWalletInfo, checkCouponUrl } from '../cardService';
import { useState, useEffect } from 'react';


export function NotifyCoupon() {

    const [cardInfo, setCardInfo] = useState({ programName: '', logo: '', logoModal: '', image: '', backgroundColor: '' });
    const currentYear = new Date().getFullYear();
    const { couponLink } = useParams();
    const [isvalidLink, setIsvalidLink] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const decodedRedirectUrl = atob(couponLink);

    // Init Google Link and graphical environment Customization
    useEffect(() => {
        fetchWalletInfo()
            .then(info => {
                setCardInfo(info);

                checkCouponUrl(couponLink)
                    .then(isValid => {
                        if (isValid) {
                            setIsvalidLink(true);
                            setIsLoading(false);
                            console.log("L'URL du coupon est valide.");
                            window.location.assign(decodedRedirectUrl);
                        } else {
                            setIsvalidLink(false);
                            setIsLoading(false);
                            console.log("L'URL du coupon n'est pas valide.");
                            // Autres traitements si l'URL n'est pas valide
                        }
                    })
                    .catch(error => {
                        setIsvalidLink(false);
                        setIsLoading(false);
                        console.error("Une erreur s'est produite lors de la vérification de l'URL:", error);
                    });                
            });
    }, []);

    return (
        <>

            <header className="main-content optinout-page">
                <div className="logo">
                    <img src={cardInfo.logo} alt={cardInfo.programName} />
                </div>
                <div className="hero-header">
                    <img src={cardInfo.image} alt={cardInfo.programName} />
                </div>
                <div className="content-text">
                    <h3>{isvalidLink ?
                        <>Lien vers le coupon : si vous n'êtes pas redirigé automatiquement vers votre coupon, cliquez sur ce lien <a href={decodedRedirectUrl}>{decodedRedirectUrl}</a> </>
                        : isLoading ? '' : "Une erreur inattendue s'est produite"}
                    </h3>
                    {isLoading ? <div className="custom-loader"></div> : '' }                   
                </div>
                <div className="footer">

                </div>
            </header>
            <div className="copyright">&copy;Coypright Mosaicmaker {currentYear}</div>
        </>
    );
}