import { Home } from "./components/Home";
import { Cards } from "./components/Cards";
import { OptinOut } from "./components/OptinOut";
import { CreateCard } from "./components/CreateCard";
import { Help } from "./components/Help";
import { NotifyCard } from "./components/NotifyCard";
import { NotifyCoupon } from "./components/NotifyCoupon";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
      path: '/cards/:cardId',
      element: <Cards />
  },
  {
     path: '/cards/:cardId/optinout',
      element: <OptinOut />,
    },
    {
        path: '/create/:cardIdEan',
        element: <CreateCard />
    },
    {
        path: '/help',
        element: <Help />
    },
    {
        path: '/notif/:cardId',
        element: <NotifyCard />
    },
    {
        path: '/notif/coupon/:couponLink',
        element: <NotifyCoupon />
    },
];

export default AppRoutes;
