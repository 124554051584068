import React, { useEffect, useCallback } from 'react';
import { Toast, Stack } from 'react-bootstrap';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useState} from 'react';
import { init } from '../notification';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getMessaging, onMessage } from "firebase/messaging";
import googleWalletButtom from '../assets/btn_gw.svg'
import icoCardContent from '../assets/ico_card_content.svg'
import icoFleche from '../assets/ico_fleche.svg'
import icoNotificationFooter from '../assets/ico_notification_footer.svg'
import icoNotificationModal from '../assets/ico_notification_modal.svg'
import { Modal } from 'react-bootstrap';
import { fetchWalletInfo, fetchJwtToken, SetOptInOut, GetOptInOut, isAndroid } from '../cardService';
import { fetchToken } from '../firebase';
import { sendServerFcmToken } from '../notification';

 export function Cards() {
    
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [isTokenFound, setTokenFound] = useState(false);
    const [jwtGoogleButton, setJwtgoogleButton] = useState("");
     const [cardInfo, setCardInfo] = useState({ programName: '', logo: '', logoModal: '', image: '', backgroundColor: '' });

     const [consent, setConsent] = useState(false);
     const [fcmToken, setFcmToken] = useState("");
     const [consentTechnicalPwa, setConsentTechnicalPwa] = useState('default'); // default, denied or granted
     const [endProcess, setEndProcess] = useState({ status: '', message: '' });
       
     const location = useLocation();
     const navigate = useNavigate();
     const { cardId } = useParams();
     localStorage.setItem('cardId', cardId);

     const askForNotification = () => {
         setShow(false);
         if (Notification.permission !== "denied") {
             init(setTokenFound)
                 .then(message => {
                     //console.log("Success:", message);
                     setConsentTechnicalPwa(Notification.permission);
                     traitementOptIn();
                 })
                 .catch(error => {
                     setConsentTechnicalPwa(Notification.permission);
                     //console.error("Error:", error.message);
                     if (error.status === "default") {
                         console.log("default bubbled-up");
                     }
                 });
         }
     };

    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
        //console.log('onMessgeListener from app.js');
        setNotification({ title: payload.notification.title, body: payload.notification.body })
        //setShow(true);
    });

    // Init Google Link and graphical environment Customization
     useEffect(() => {
         fetchJwtToken(cardId)
             .then(jwt => {
                 setJwtgoogleButton(jwt.url);
             });

         fetchWalletInfo()
             .then(info => {
                 setCardInfo(info);
             });
     }, [cardId]);

     // Init Css customization
     useEffect(() => {
         document.documentElement.style.setProperty('--background-color', cardInfo.backgroundColor);
     }, [cardInfo.backgroundColor]);

     // Init token and Notification technical permission
     // Init consent
     useEffect(() => {
         if ('Notification' in window) {
             setConsentTechnicalPwa(Notification.permission);
             if (Notification.permission === "denied" || Notification.permission === "default") {
                 setShow(true);
             }
             if (Notification.permission === "granted") {
                 setShow(false);
             }
         }
     });

     useEffect(() => {
         let currentToken = localStorage.getItem('fcmToken');
         if (currentToken !== undefined && currentToken !== null) {
             // refetch token in order to refresh token if necessary
             fetchToken(setTokenFound)
                 .then((token) => {
                     // token renewal
                     if (currentToken !== token && token !== null && token !== undefined && token !== "" && token !== "undefined") {
                         localStorage.setItem('fcmToken', token);
                         const cardId = localStorage.getItem("cardId");
                         sendServerFcmToken(token, cardId);
                     }
                     setFcmToken(token);
                     setTokenFound(true);
                 })
                 .catch(error => {
                     console.error('Synchronous error caught:', error);
                 });
         }

         if (fcmToken !== null && fcmToken !== undefined && fcmToken !== "" && fcmToken !== 'undefined') {
             GetOptInOut(cardId, fcmToken)
                 .then(result => {
                     setConsent(result.consent);
                     if (!result.consent) {
                         setShow(true);
                     } else {
                         setShow(false);
                     }
                 })
                 .catch(error => {
                     if (error.response) {
                         //console.error(`Server responded with status ${error.response.status}: ${error.responseText}`);
                     } else {
                         //console.error('Fetch failed:', error.message);
                     }
                 });
         }
     }, [cardId, fcmToken]);

     const optinConsent = () => {
        if ('Notification' in window) {
            if (Notification.permission === "default" || Notification.permission === "granted") {
                try {
                    init(setTokenFound)
                        .then(message => {
                            setConsentTechnicalPwa(Notification.permission);
                            //console.log("Success:", message);
                            traitementOptIn();
                        })
                        .catch(error => {
                            setConsentTechnicalPwa(Notification.permission);
                            //console.error("Error:", error.message);
                        });
                } catch (error) {
                    setEndProcess({ status: 'failed', message: "Une erreur inattendue est arrivée. Votre activation n'a pas pu être traitée." });
                    //console.log(error);
                }
            } else {
                // Refus technique des notifications déjà enregistré, mais cas inaccessible optinConsent désactivé au préalable
                return;
            }
        }
     }

     const traitementOptIn = () => {
         const textconfirmOptin = (
             <>
                 Vos notifications sont maintenant activées.<br /><br />
                 Pour que vos notiifications vous parviennent correctement assurez-vous aussi de bien <img src={googleWalletButtom} onClick={walletButtonClick} className="cta-toaster-gw" alt="add to google wallet" />.
             </>);

         if (Notification.permission === "granted") {
             let token = localStorage.getItem('fcmToken');
             SetOptInOut(cardId, token, true)
                 .then((response) => {
                     if (response.result === "OK") {
                         setEndProcess({
                             status: 'processed', message: textconfirmOptin
                         });
                         setConsent(true);
                     }
                 })
                 .catch(error => {
                     setConsent(false);
                     setEndProcess({ status: 'failed', message: "Une erreur inattendue est arrivée. Votre activation n'a pas pu être traitée." });
                     //console.log(error);
                 });
         }
     }

     const optoutConsent = () => {
         let token = localStorage.getItem('fcmToken') ?? ""; // An optout should always apply even if fcmToken is missing in localstorage
         try {
             SetOptInOut(cardId, token, false)
                 .then((response) => {
                     if (response.result === "OK") {
                         setEndProcess({ status: 'processed', message: "Vos notifications sont maintenant désactivées." });
                         setConsent(false);
                     }
                 })
                 .catch(error => {
                     setEndProcess({ status: 'failed', message: "Une erreur inattendue est arrivée. Votre désactivation n'a pas pu être traitée." });
                     throw error;
                 });
         } catch (error) {
             setConsent(true);
             setEndProcess({ status: 'failed', message: "Une erreur inattendue est arrivée. Votre désactivation n'a pas pu être traitée." });
             //console.log(error);
         }
     }

    const walletButtonClick = () => {
        let path = jwtGoogleButton;
        window.location.href = path;
     }

     const isDeviceAndroid = isAndroid();

     const technicalNotificationDisclaimer = isDeviceAndroid ?
         <>
             Vous avez bloqué l’autorisation de réception de notification liées à votre programme {cardInfo.programName} (ou de fidélité) au sein de votre smartphone.
             Pour les activer, aller dans Paramètres Internet de votre navigateur (et non de votre smartphone). <br />
             <span className="modal-cta-help" onClick={() => navigate("/help/") }>Cliquez ici pour plus de détail.</span>
         </>
         : <>
             Vous avez bloqué l’autorisation de réception de notification liées à votre programme {cardInfo.programName} (ou de fidélité) au sein de votre ordinateur.
             Pour les activer, aller dans Paramètres Internet de votre navigateur (et non de votre smartphone). <br />
             <span className="modal-cta-help" onClick={() => navigate("/help/")}>Cliquez ici pour plus de détail.</span>
         </>
        ;

     const optinDisclaimer = consent ? 'activées' : 'désactivées';

     const standardModal = (
         <>
             <p>
             Pour ne manquer aucune offre de votre {cardInfo.programName}.
             </p>
             <div className="emphasize">
                 Autorisez les notifications sur votre smartphone.
             </div>
             <button onClick={askForNotification} className="modal-OK">OK</button>
         </>
     );

     const currentYear = new Date().getFullYear();
    
    return (
        <>
            <Modal
                backdrop="static"
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="model-smartecard"
                aria-labelledby="Acceptation des notifications"
                size="xl"
                centered
            >
                <Modal.Header>
                    <img src={icoNotificationModal} className="modal-ico"/>
                    <Modal.Title>
                        Notifications
                    </Modal.Title>
                    <img src={cardInfo.logoModal} className="modal-logo" />
                </Modal.Header>
                <Modal.Body>
                    {consentTechnicalPwa === 'denied' ? technicalNotificationDisclaimer : standardModal}
                </Modal.Body>
            </Modal>

            <ToastContainer
                className="p-3"
                position="middle-end"
                style={{ zIndex: 1 }}
            >
                <Toast
                    onClose={() => setEndProcess({ status: "", message: "" }) }
                    show={endProcess.status === "processed" || endProcess.status == "failed"}
                    bg={endProcess.status === "failed" ? "danger" : "success"}
                    delay={15000}
                    autohide
                >
                    <Toast.Header>
                        <div className="me-auto title-toast">{endProcess.status === "processed" ? "Confirmation" : (endProcess.status === "failed" ? "Erreur" : "")}</div>
                    </Toast.Header>
                    <Toast.Body><div className="text-toast">{endProcess.message}</div></Toast.Body>
                </Toast>
            </ToastContainer>

            <header className="main-content">
                <div className="logo">
                    <img src={cardInfo.logo} alt={cardInfo.programName} />
                </div>
                <div className="hero-header">
                    <img src={cardInfo.image} alt={cardInfo.programName} />
                </div>
                <div className="content-text">
                    <div className="cta-text">
                        <img src={icoCardContent} />
                        <div>Enregistrer votre carte sur <span>Google Wallet</span> pour </div>
                    </div>
                    <ul className="claims">
                        <li>
                            <img src={icoFleche} />
                            <div>Avoir votre carte à portée de main lors de votre passage en caisse</div>
                        </li>
                        <li>
                            <img src={icoFleche} />
                            <div>Ne manquer aucune offre exclusive&nbsp;!</div>
                        </li>
                    </ul>
                    <img src={googleWalletButtom} onClick={walletButtonClick} className="cta-google-wallet" alt="add to google wallet" />
                </div>
                <div className="footer">
                    <img src={icoNotificationFooter} className="footer-icon" />
                    <div className="footer-ctas" >
                        <div className="footer-ctas-title">
                            Notifications : {consentTechnicalPwa === 'denied' ? technicalNotificationDisclaimer : optinDisclaimer}
                        </div>
                        <div className="footer-ctas-buttons" >
                            <Stack direction="horizontal" gap={3}>
                                {consentTechnicalPwa === 'denied' ?
                                    '' :
                                    <>
                                        <button id="cta-optout" onClick={() => optoutConsent()} className="p-2 cta-optout" disabled={consent ? false : true}>Désactiver</button>
                                        <button id="cta-optin" onClick={() => optinConsent()} className="p-2 cta-optin" disabled={consent ? true : false}>Activer</button>
                                    </>
                                }
                            </Stack>
                        </div>
                    </div>
                </div>                   
            </header>
            <div className="copyright">&copy;Coypright Mosaicmaker {currentYear}</div>
        </>
    );
    
}