import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
//import { messaging } from './firebaseServiceWorkerRegistration'
import { config } from './config';

const environment = config.ENVIRONMENT;
console.log(`Firebase : ${environment}`);

const vapidKey = config.FIREBASE_VAPIDKEY;

// Cutom firebase service Worker registration with multple environments

const firebaseConfig = {
    apiKey: config.FIREBASE_APIKEY,
    authDomain: config.FIREBASE_AUTHDOMAIN,
    projectId: config.FIREBASE_PROJECTID,
    storageBucket: config.FIREBASE_STORAGEBUCKET,
    messagingSenderId: config.FIREBASE_MESSAGINGSENDERID,
    appId: config.FIREBASE_APPID,
    measurementId: config.FIREBASE_MEASUREMENTID
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
    
    return getToken(messaging, { vapidKey: vapidKey })
        .then((currentToken) => {
        if (currentToken && currentToken !== null && currentToken != undefined && currentToken != "" && currentToken != "undefined") {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
           // registerApi(currentToken);
            localStorage.setItem('fcmToken', currentToken);
            return currentToken;
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

const registerApi = (token) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: token })
    };

    fetch("api/registration", requestOptions)
        .then(response => {
            console.log("ok");
            console.log(response);
        }
        )
}

export const onMessageListener = () => {
    console.log('OnMessageListener from firebase.js');
    return new Promise((resolve) => {
        onMessage((messaging,payload) => {
            console.log(payload);
            resolve(payload);
        });
    });
    }