

export const getLocalCardId = () => {
    return localStorage.getItem('cardId');
}

export const fetchJwtToken = (cardId) => {
    return fetch(`api/wallet/wallet-button-link/${cardId}`)
        .then(response => response.json())
        .catch(error => { })
}

export const fetchWalletInfo = () => {
    return fetch(`api/wallet/wallet-preview`)
        .then(response => response.json());
    
}

export const createCardInfo = (eanCardId, token) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', }
    };

    return fetch(`api/wallet/create/${eanCardId}?token=${token}`, requestOptions)
        .then(response => {
            if (response.ok)
                return response.json();
            else {
                throw response
            }
            })
}

export const GetOptInOut = (cardId, fcmToken) => {
    const requestOptions = {
        method: 'Get',
        headers: { 'Content-Type': 'application/json', },        
    };

    return fetch(`api/registration/consent/${cardId}/${fcmToken}`, requestOptions)
        .then(response => {
            if (response.ok) {
                return response.json().catch(error => {
                    // Invalid JSON response
                    throw new Error('Invalid JSON response');
                });
            } else {
                // Not a 2xx response
                return response.text().then(text => {
                    // Convert non-OK HTTP responses into errors, and attach the response text.
                    let err = new Error(`Server responded with status ${response.status}: ${text}`);
                    err.response = response;
                    err.responseText = text;
                    throw err;
                });
            }
        })
        .catch(error => {
            // Here, `error` can be:
            // - a network error (e.g., offline, CORS issues)
            // - an "Invalid JSON response" error
            // - a server error with status and responseText attached
            throw error; // Re-throw the error to the caller
        });
}

export const SetOptInOut = (cardId, fcmToken, consent) =>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify({
            cardId: cardId, 
            fcmTokenId: fcmToken,
               consent: consent
        })
    };

    return fetch(`api/registration/consent/${cardId}`, requestOptions)
        .then(async response => {
            if (response.ok) {
                return { result: "OK"};
            }
            else {
                throw response
            }
        });
}

export const setAppInstalled = (cardId) => {
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cardId: cardId })
    };

    return fetch(`api/registration/appinstalled/${cardId}`, requestOptions)
        .then(response => {
            console.log("ok");
            console.log(response);
        }
    )           
}

export const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
}


export const checkCouponUrl = (encodedUrl) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`api/wallet/checkCouponLink/${encodedUrl}`, requestOptions)
        .then(response => {
            if (!response.ok) {
                //return false;
                throw new Error('Network response was not ok');
            }
            return response.json(); // Convertit la r�ponse en JSON
        })
        .then(data => {
            if (data === true) {
                return true;
            }
            return false;
        });       
}