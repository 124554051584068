import 'bootstrap/dist/css/bootstrap.css';
import './custom.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
//import { firebaseServiceWorkerRegistration } from './firebaseServiceWorkerRegistration'
//import { config } from './config';
//import { initializeApp } from 'firebase/app';
//import { getMessaging } from "firebase/messaging";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

//// Cutom firebase service Worker registration with multple environments

//const firebaseConfig = {
//    apiKey: config.FIREBASE_APIKEY,
//    authDomain: config.FIREBASE_AUTHDOMAIN,
//    projectId: config.FIREBASE_PROJECTID,
//    storageBucket: config.FIREBASE_STORAGEBUCKET,
//    messagingSenderId: config.FIREBASE_MESSAGINGSENDERID,
//    appId: config.FIREBASE_APPID,
//    measurementId: config.FIREBASE_MEASUREMENTID
//};

//const firebaseApp = initializeApp(firebaseConfig);
//const messaging = getMessaging(firebaseApp);

//firebaseServiceWorkerRegistration(firebaseApp, messaging);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
