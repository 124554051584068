import './App.css';
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getMessaging, onMessage } from "firebase/messaging";
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { useNavigate, useLocation } from 'react-router-dom';
import { setAppInstalled } from './cardService';
import { init } from './notification';

function App() {
    const navigate = useNavigate();
    const location = useLocation();

    //const [show, setShow] = useState(true);
    const [isTokenFound, setTokenFound] = useState(false);

    useEffect(() => {
        if (location.pathname.startsWith("/create")) {
            //setShow(false);
            return;
        }
        if (!location.pathname.startsWith("/cards/") && 
            !location.pathname.startsWith("/help") && 
            !location.pathname.startsWith("/notif/") ) {
            let cardId = localStorage.getItem('cardId');
            if (cardId !== undefined && cardId !== null) {
                navigate(`/cards/${cardId}`);
            }
            //setShow(false);
        }
        else {
            let fcmToken = localStorage.getItem('fcmToken');
            if (fcmToken !== undefined && fcmToken !== null)
                setTokenFound(true);
        }
    })
    
    useEffect(() => {
        window.addEventListener('appinstalled', (e) => {
            let cardId = localStorage.getItem('cardId');
            console.log("app installed!!");
            setAppInstalled(cardId);
        })


    }, []);
   
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
        console.log('onMessgeListener from app.js');
    
        console.log(payload);
    });

    const askForNotification = () => {
        init(setTokenFound);
    }
    

    return (
        <Layout>
            <Routes>
                {AppRoutes.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} element={element} />;
                })}
            </Routes>
        </Layout>
    );
}


export default App;