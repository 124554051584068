import { useParams, useNavigate } from "react-router-dom"
import { fetchWalletInfo } from '../cardService';
import { useState, useEffect } from 'react';


export function NotifyCard() {

    const navigate = useNavigate();
    const [cardInfo, setCardInfo] = useState({ programName: '', logo: '', logoModal: '', image: '', backgroundColor: '' });
    const currentYear = new Date().getFullYear();
    const { cardId } = useParams();

    const cardRedirectUrl = `https://pay.google.com/gp/v/object/${cardId}`;

    // Init Google Link and graphical environment Customization
    useEffect(() => {
        fetchWalletInfo()
            .then(info => {
                setCardInfo(info);
                window.location.assign(cardRedirectUrl);
                //navigate(`/cards/${cardId}`);
            });
    }, []);   

    return (
        <>

            <header className="main-content optinout-page">
                <div className="logo">
                    <img src={cardInfo.logo} alt={cardInfo.programName} />
                </div>
                <div className="hero-header">
                    <img src={cardInfo.image} alt={cardInfo.programName} />
                </div>
                <div className="content-text">
                    <h3>Lien vers la carte : si vous n'êtes pas redirigé automatiquement vers votre carte, cliquez sur ce lien <a href={cardRedirectUrl}>{cardRedirectUrl}</a></h3>
                    <div class="custom-loader"></div>

                </div>
                <div className="footer">

                </div>
            </header>
            <div className="copyright">&copy;Coypright Mosaicmaker {currentYear}</div>
        </>
    );
}