import { useNavigate } from "react-router-dom"
import { fetchWalletInfo } from '../cardService';
import { useState, useEffect } from 'react';
import burgerSite from '../assets/burgerSite.png'
import troisPoints from '../assets/3points.png'
import autoNotif from '../assets/autoNotif.png'
import autoriserBloacage from '../assets/autoriserBloacage.png'
import notifications from '../assets/notifications.png'
import Parametres from '../assets/Parametres.png'
import site_telechargement from '../assets/site_telechargement.png'

export function Help() {

    const navigate = useNavigate();
    const [cardInfo, setCardInfo] = useState({ programName: '', logo: '', logoModal: '', image: '', backgroundColor: '' });
    const currentYear = new Date().getFullYear();

    // Init Google Link and graphical environment Customization
    useEffect(() => {
        fetchWalletInfo()
            .then(info => {
                setCardInfo(info);
            });
    }, []);

    return (
        <>

            <header className="main-content help-page">
                <div className="logo">
                    <img src={cardInfo.logo} alt={cardInfo.programName} />
                </div>
                <div className="hero-header">
                    <img src={cardInfo.image} alt={cardInfo.programName} />
                </div>
                <div className="content-text">
                    <h1>Aide</h1>
                    <p>Pour autoriser l’envoi de notification mobile lié à votre programme {cardInfo.programName}, réalisez les actions suivantes :</p>
                    <p>Ouvrez votre navigateur web et cliquez sur le burger en bas de page pour accéder à la rubrique Paramètres internet</p>

                    <div className="help-intro-images">
                        <img src={burgerSite} />
                        <img src={Parametres} />
                    </div>
                    <p>Cliquez sur les rubriques suivantes :</p>
                    <ul className="help-points">
                        <li>
                            <p><span className="help-number">1.</span>Sites et téléchargements</p>
                            <div>
                                <img src={site_telechargement} />
                            </div>
                        </li>
                        <li>
                            <p><span className="help-number">2.</span>Notifications</p>
                            <div>
                                <img src={notifications} />
                            </div>
                        </li>
                        <li>
                            <p><span className="help-number">3.</span>&#8942;</p>
                            <div>
                                <img src={troisPoints} />
                            </div>
                        </li>
                        <li>
                            <p><span className="help-number">4.</span>Autorisation ou blocage des sites</p>
                            <div>
                                <img src={autoriserBloacage} />
                            </div>
                        </li>
                        <li>
                            <p><span className="help-number">5.</span>Autoriser</p>
                            <div>
                                <img src={autoNotif} />
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="footer">

                </div>
            </header>
            <div className="copyright">&copy;Coypright Mosaicmaker {currentYear}</div>
        </>
    );
}